/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url("../images/dribbble_1.gif");
  height: 450px;
  width: auto;
  background-position: center;
  background-repeat: repeat-y;
}

.four_zero_four_bg h1 {
  font-size: 30px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}
.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #db2228;
  margin: 20px 0;
  display: inline-block;
  border-radius: 5px;
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.contant_box_404 {
  margin-top: -50px;
}
