@layer components {
  td {
    @apply bg-white py-2 px-2 text-black;
  }

  td:first-child {
    @apply sticky left-0 bg-white text-black;
  }

  th {
    @apply bg-primary-500 py-2 px-2 text-white;
  }

  th:first-child {
    @apply sticky left-0;
  }
}